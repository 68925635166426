import { Button, Errors, Form } from '@troon/ui';
import { createSignal, Switch, Match } from 'solid-js';
import { Navigate } from '@solidjs/router';
import { produce } from 'solid-js/store';
import { useAuthStore } from '../flow';
import { gql, mutationAction, useMutation } from '../../../graphql';
import { SharedPasswordEntry } from './shared-password-entry';

export function ResetNewPassword() {
	const [store, setStore] = useAuthStore();
	const [valid, setValid] = createSignal(false);

	const resetAction = useMutation(
		resetPassword(async () => {
			setStore(
				produce((state) => {
					state.data = { email: state.data.email! };
					state.flow = 'login';
					state.step = '/auth/login/';
				}),
			);
		}),
	);

	return (
		<Switch>
			<Match when={!store.data.email || !store.data.confirmationCode}>
				<Navigate href="/auth" />
			</Match>

			<Match when={true}>
				<Form action={resetAction} document={resetPasswordMutation} class="flex flex-col gap-4">
					<input name="email" type="hidden" value={store.data.email} />
					<input name="confirmationCode" type="hidden" value={store.data.confirmationCode} />

					<SharedPasswordEntry
						onUpdate={(valid) => {
							setValid(valid);
						}}
					/>

					<Errors />

					<Button type="submit" disabled={!valid()}>
						Set password
					</Button>
				</Form>
			</Match>
		</Switch>
	);
}

const resetPasswordMutation = gql(`
mutation resetPassword($email: String!, $password: String!, $confirmationCode: String!) {
	resetPassword(email: $email, password: $password, confirmationCode: $confirmationCode) {
		needsConfirmation
	}
}`);

const resetPassword = (onSuccess: () => Promise<void>) =>
	mutationAction(resetPasswordMutation, {
		toast: 'Your password has been reset. Please log in using your new password.',
		onSuccess,
		track: {
			event: 'passwordReset',
		},
	});
