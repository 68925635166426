import { createStore, produce } from 'solid-js/store';
import { For, Match, Show, Switch, createEffect, onCleanup } from 'solid-js';
import { Navigate, useLocation } from '@solidjs/router';
import { isServer } from 'solid-js/web';
import { ActivityIndicator } from '@troon/ui';
import { useUser } from '../../providers/user';
import { ErrorBoundary } from '../../components/error-boundary';
import { Login } from './flow/01-login';
import { UserExists } from './flow/00-user-exists';
import { CreatePassword } from './flow/01-create-password';
import { Name } from './flow/02-name';
import { PostalCode } from './flow/03-postalcode';
import { ForgotPassword } from './flow/02-forgot-code';
import { AuthFlowContext, defaultStore, flowFromStep, flows } from './flow';
import { ResetNewPassword } from './flow/03-new-password';
import { MagicLink } from './flow/02-magic-link';
import { Join } from './flow/02-join';
import { StepHeader } from './step-header';
import type { JSX } from 'solid-js';
import type { FlowStep, StoreState } from './flow';

export { useAuthStore } from './flow';

type Props = {
	data?: Record<string, string | undefined>;
	descriptions?: Record<FlowStep, () => JSX.Element>;
	headers?: Record<FlowStep, () => JSX.Element>;
	headings?: Record<FlowStep, string>;
	onComplete?: () => void;
	redirect?: string;
	inline?: boolean;
	showSteps?: boolean;
};

export function AuthFlow(props: Props) {
	const user = useUser();
	const location = useLocation();
	const [authStore, setAuthStore] = createStore<StoreState>({
		...defaultStore,
		data: { ...defaultStore.data, ...props.data } as Record<string, string>,
		flow: flowFromStep(ensureTrailingSlash(location.pathname)) ?? defaultStore.flow,
		redirect: props.redirect ?? ensureTrailingSlash(location.pathname.startsWith('/auth') ? '/' : location.pathname),
		step: location.pathname.startsWith('/auth/') ? ensureTrailingSlash(location.pathname) : '/auth/',
	});

	function handleComplete() {
		!props.inline && history.replaceState({}, '', authStore.redirect);
		if (props.onComplete) {
			props.onComplete();
		}
	}

	createEffect(() => {
		if (!isServer) {
			!props.inline && history.replaceState({}, '', authStore.step);
			setAuthStore(
				produce((state) => {
					state.highestStepIndex = Object.keys(flows[state.flow]).indexOf(state.step);
				}),
			);
		}
	});

	onCleanup(() => {
		if (!isServer && typeof history !== 'undefined') {
			!props.inline && history.replaceState({}, '', authStore.redirect);
			setAuthStore({ flow: 'login', step: '/auth/', data: {} });
		}
	});

	return (
		<div class="flex flex-col gap-4">
			<AuthFlowContext.Provider value={[authStore, setAuthStore]}>
				<ErrorBoundary>
					<Show
						when={!user()}
						fallback={
							<>
								<ActivityIndicator>Logging in…</ActivityIndicator>
								<Navigate href={authStore.redirect.startsWith('/auth') ? '/' : authStore.redirect} />
							</>
						}
					>
						<StepHeader
							header={props.headers && props.headers[authStore.step]}
							heading={props.headings && props.headings[authStore.step]}
							description={props.descriptions && props.descriptions[authStore.step]}
						/>
						<Switch fallback={<Navigate href="/" />}>
							<Match when={authStore.step === '/auth/'}>
								<UserExists
									nextNewUser={props.inline ? '/auth/join/' : '/auth/join/password/'}
									nextReturningUser={props.inline ? '/auth/login/' : '/auth/login/'}
								/>
							</Match>
							<Match when={authStore.step === '/auth/login/'}>
								<Login onComplete={handleComplete} />
							</Match>
							<Match when={authStore.step === '/auth/magic-link/'}>
								<MagicLink onComplete={handleComplete} />
							</Match>
							<Match when={authStore.step === '/auth/reset/'}>
								<ForgotPassword />
							</Match>
							<Match when={authStore.step === '/auth/reset/password/'}>
								<ResetNewPassword />
							</Match>
							<Match when={authStore.step === '/auth/join/password/'}>
								<CreatePassword />
							</Match>
							<Match when={authStore.step === '/auth/join/name/'}>
								<Name />
							</Match>
							<Match when={authStore.step === '/auth/join/postal-code/'}>
								<PostalCode onComplete={handleComplete} />
							</Match>
							<Match when={authStore.step === '/auth/join/'}>
								<Join />
							</Match>
						</Switch>
					</Show>

					<Show when={props.showSteps !== false && authStore.step !== '/auth/' && authStore.step !== '/auth/login/'}>
						{/* TODO: does this need to be reusable? */}
						<ul role="group" aria-label="Progress" class="mt-6 flex flex-row justify-center gap-2">
							<For each={Object.entries(flows[authStore.flow])}>
								{([key, label], index) => (
									<li
										aria-current={
											Object.keys(flows[authStore.flow]).indexOf(authStore.step) === index() ? 'step' : undefined
										}
										class="text-brand-100 aria-current-step:text-brand"
									>
										<button
											class="group size-8"
											onClick={() => {
												setAuthStore({ step: key });
											}}
											disabled={index() >= authStore.highestStepIndex}
										>
											<span class="block h-1 bg-current group-hover:enabled:bg-brand-700" />
											<span class="sr-only">
												Step {index() + 1}: {label.heading}
											</span>
										</button>
									</li>
								)}
							</For>
						</ul>
					</Show>
				</ErrorBoundary>
			</AuthFlowContext.Provider>
		</div>
	);
}

function ensureTrailingSlash(input: string) {
	return input.endsWith('/') ? input : `${input}/`;
}
