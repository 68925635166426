import { Link, Logo } from '@troon/ui';
import { iOSAppStoreUrl } from '../modules/urls';
import { Content } from './content';
import { SupportButton } from './support';

export function Footer() {
	return (
		<div class="border-t border-neutral pt-8">
			<Content>
				<div class="mb-8 flex flex-row justify-between border-b border-neutral pb-8">
					<Link href="/">
						<Logo class="w-28 shrink-0 grow-0 basis-28 text-neutral-900 hover:text-neutral-800" />
						<span class="sr-only">Troon home</span>
					</Link>
					<ul class="flex shrink flex-row flex-wrap">
						<li>
							<SupportButton appearance="transparent-current" title="Contact support" class="font-normal normal-case">
								Contact support
							</SupportButton>
						</li>
					</ul>
				</div>
				<div class="flex w-full flex-row flex-wrap justify-between gap-6">
					<div class="flex grow flex-col items-center gap-6 sm:items-start">
						<ul class="flex flex-row gap-6">
							<li>
								<a href="https://twitter.com/troon" rel="noopener nofollow" target="_blank">
									<img src="/assets/images/social-x-twitter.svg" alt="Troon on X (Twitter)" class="size-8" />
								</a>
							</li>
							<li>
								<a href="https://www.linkedin.com/company/troon/" rel="noopener nofollow" target="_blank">
									<img src="/assets/images/social-linkedin.svg" alt="Troon on Linkedin" class="size-8" />
								</a>
							</li>
							<li>
								<a href="https://www.facebook.com/troon" rel="noopener nofollow" target="_blank">
									<img src="/assets/images/social-fb.svg" alt="Troon on Facebook " class="size-8" />
								</a>
							</li>
						</ul>
						<div class="flex flex-col gap-2">
							<p class="text-sm">© 2024 Troon Digital, LLC All Rights Reserved</p>
							<p class="text-xs">15044 N. Scottsdale Road, Scottsdale, AZ 85254</p>
						</div>
					</div>
					<div class="flex grow flex-col items-center justify-center gap-2 sm:items-end">
						<a href={iOSAppStoreUrl} target="_blank">
							<img
								src="/assets/images/app-store.png"
								alt="Available on the Apple App Store"
								width="135px"
								height="40px"
							/>
						</a>
					</div>
				</div>
			</Content>
		</div>
	);
}
