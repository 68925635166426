import type { JSX } from 'solid-js';

type Props = JSX.SvgSVGAttributes<SVGSVGElement>;

export function ImageOverlay(props: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 1206 16"
			{...props}
			class={`pointer-events-none z-30 w-full fill-white ${props.class}`}
		>
			<path d="M1329-9v-2l-2-8-3-21-1-12-1-21 1-11v-17c2-6 2-11 0-14l1-22 2-20 1-20 1-18 2-29v-60c1 1 1-7 1-7l-2-35-2-22-1-4-2-6c-2-8-1-24 0-32l1-8v-12c2-13 2-26-2-38l-6-7c-12-11-28-15-43-20l-17-5-19-3-9-1-8-1h-11l-14-1h-10l-40 3-22 1-5 2-72-1-40-1h-42l-25 2-52 1h-8l-27 1-40 1-31 1-22 1H444l-27-1a162 162 0 0 1-9 1h-87l-28-1h-18l-9-1-10 1h-13l-13-1h-16l-13-1h-49l-9-1h-12l-12-1H63l-74-1-17-1h-10l-76-3c-9 0-16 5-22 15l-2 8-3 18a2640 2640 0 0 0-1 63v13l-1 23 3 25 1 11a8504 8504 0 0 0 12 66l1 10 1 36 1 18v37a35445 35445 0 0 1-1 28v11l-1 7c0 16-1 34-7 46l-9 26-1 14c0 9 2 15 7 18l13 15 9 9 6 4 10 5 4 3 3 1 10 3 8 3 25 5h9l19 1 17 1 11-1 7-1h4l29-3 39-3 16-2h14l10-1 13 1h11l30 1 20-1h97l33 1h103l10-1h9l305 1h16l18-1h5l14 1h38l13-1 16-1c-2 1 27 1 27 1l27-1h23l20-1 12 2 25 3 29 2h71c-1 1 48 2 48 2h13l6 1 34 3 27 2 21-3 23-4 14-6 20-10 3-3 8-10c4-8 3-25 0-34ZM1173 3l-14-1h-24l-12 1h-81l-39 2c-1 2-2 1-2 1h-5l-26 3-13 2-21 1-15-1-7 1h-28l-11 2h-6l-19-1h-7l-19-1-19-1h-33l-5 1h-9l-309 1h-20l-19-1h-9l-4-1-8 1-11 2c-4-3-12-4-18-2l-4-1-12 1-15-2h-20l-4 1-9-1-17-1h-3l-8 1c2-1-23-2-23-2l-9-1c-1 1-27-1-27-1l-26-3-7 1-22-2-12-1-9 1H91L79 3H40L30 4h-6L-1 3l1-19 1-4 2-10c2-8 4-16 4-25L5-70l-3-8-3-11-5-24-5-24-1-9-1-5-1-7-6-62v-77l2-51v-14l1-28v-15l-1-10-1-11 1-10 46 1h26l17-1h8l7 1h96l30 1h17l17 1a4884 4884 0 0 1 27 0h66l15 1h107l299 1 7-1 54 2h126l18 1h68l8-1c-1 1 0 1 2 1l4-1a4400 4400 0 0 0 78 1h15l10 1 15-1h6l16 1 12 1 11 1-4 41 4 19 2 5 1 8 2 11 2 15c2 14 5 30 3 44l-2 6-2 6-1 3-4 11-6 18-3 10v4l-1 36 1 9 2 14 1 7 1 14 1 6 1 7 2 10a217 217 0 0 1 4 64v7l-3 10c-3 11-5 22-3 35l-3 9h-32Zm113 8h1-1Z" />
		</svg>
	);
}
