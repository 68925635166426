import { createContext, useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import type { Component } from 'solid-js';

export type Flow = 'login' | 'magicLink' | 'forgotPassword' | 'register' | 'registerInline';

export const flows: Record<Flow, Record<string, { heading: string; description?: Component }>> = {
	login: {
		'/auth/': { heading: 'Welcome to Troon', description: Welcome },
		'/auth/login/': { heading: 'Welcome back', description: WelcomeBack },
	},

	magicLink: {
		'/auth/': { heading: 'Welcome to Troon', description: Welcome },
		'/auth/login/': { heading: 'Welcome back', description: WelcomeBack },
		'/auth/magic-link/': { heading: 'Check your email', description: MagicLink },
	},

	forgotPassword: {
		'/auth/': { heading: 'Welcome to Troon', description: Welcome },
		'/auth/login/': { heading: 'Welcome back', description: WelcomeBack },
		'/auth/reset/': { heading: 'Reset your password', description: ForgotCode },
		'/auth/reset/password/': { heading: 'Change password', description: NewPassword },
	},

	register: {
		'/auth/': { heading: 'Welcome to Troon', description: Welcome },
		'/auth/join/password/': { heading: 'Create Your Password', description: CreatePassword },
		'/auth/join/name/': { heading: 'What’s Your Name?', description: Name },
		'/auth/join/postal-code/': { heading: 'What’s Your Postal Code?', description: PostalCode },
	},

	registerInline: {
		'/auth/': { heading: 'Log in or sign up' },
		'/auth/join/': { heading: 'Sign up' },
	},
} as const;

function Welcome() {
	return (
		<p>
			Enter your email below to log into your <b class="font-semibold">Troon Rewards</b> account. If you don’t have one,
			you’ll be prompted to create one for free.
		</p>
	);
}

function CreatePassword() {
	return <p>Keep your account secure with a strong password. Review the password requirements below.</p>;
}

function WelcomeBack() {
	const [store] = useAuthStore();
	return (
		<p>
			We found an existing Troon Rewards account for <b>{store.data.email}</b>. Enter your password to log in and access
			your account.
		</p>
	);
}

function MagicLink() {
	const [store] = useAuthStore();
	return (
		<p>
			We sent a magic link to <b>{store.data.email}</b>. Click on the link in the email to log in, or enter the code
			below.
		</p>
	);
}

function ForgotCode() {
	return <p>Check your email for a confirmation code.</p>;
}

function Name() {
	return <p>Let us know who you are.</p>;
}

function NewPassword() {
	return <p>Create a password with at least 8 letters and numbers.</p>;
}

function PostalCode() {
	return <p>Earn special promotions from Troon courses in your area.</p>;
}

export type FlowStep = keyof (typeof flows)[Flow];

export type StoreState = {
	data: Record<string, string>;
	flow: Flow;
	highestStepIndex: number;
	redirect: string;
	step: FlowStep;
};

export const defaultStore: StoreState = {
	data: {},
	flow: 'login',
	highestStepIndex: 0,
	redirect: '/',
	step: Object.keys(flows.login)[0] as FlowStep,
};

type Store = ReturnType<typeof createStore<StoreState>>;
export const [authStore, setAuthStore] = createStore<StoreState>(defaultStore);
export const AuthFlowContext = createContext<Store>([authStore, setAuthStore]);

export function useAuthStore() {
	return useContext(AuthFlowContext);
}

export function flowFromStep(step: FlowStep): Flow | undefined {
	const found = Object.entries(flows).find(([, steps]) => Object.keys(steps).includes(step));
	if (found) {
		return found[0] as Flow;
	}
	return 'login';
}
