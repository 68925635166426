import { Button } from '@troon/ui';
import { createSignal, Switch, Match } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { Navigate } from '@solidjs/router';
import { useAuthStore } from '../flow';
import { SharedPasswordEntry } from './shared-password-entry';

export function CreatePassword() {
	const [store, setStore] = useAuthStore();
	const trackEvent = useTrackEvent();
	const [valid, setValid] = createSignal(false);

	return (
		<Switch>
			<Match when={!store.data.email}>
				<Navigate href="/auth" />
			</Match>

			<Match when={true}>
				<form
					class="flex flex-col gap-4"
					onSubmit={(e) => {
						e.preventDefault();
						e.stopPropagation();

						if (valid()) {
							trackEvent('signupEnteredPassword', { email: store.data.email });
							setStore('step', '/auth/join/name/');
						}
					}}
				>
					<input name="email" type="hidden" value={store.data.email} />

					<SharedPasswordEntry
						onUpdate={(valid) => {
							setValid(valid);
						}}
					/>

					<Button type="submit" disabled={!valid()}>
						Continue
					</Button>
				</form>
			</Match>
		</Switch>
	);
}
