import { Picture } from '@troon/ui';
import { ImageOverlay } from './image-overlay';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	src?: string | null;
	alt?: string;
}>;

export function Hero(props: Props) {
	return (
		<div class="relative -top-16 -mb-16 flex min-h-64 flex-col items-center overflow-hidden bg-brand sm:min-h-72 md:min-h-96">
			<Picture
				src={props.src!}
				alt={props.alt ?? ''}
				sizes={[
					[480, 256],
					[960, 384],
					[1600, 384],
				]}
				class="absolute size-full bg-center object-cover brightness-75"
				loading="eager"
			/>
			<div class="z-10 flex grow items-center justify-center pt-16">{props.children}</div>
			<ImageOverlay />
		</div>
	);
}
