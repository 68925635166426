import {
	Button,
	Dialog,
	DialogAction,
	DialogActions,
	DialogTrigger,
	Errors,
	Form,
	Input,
	Label,
	TextField,
} from '@troon/ui';
import { useSubmission } from '@solidjs/router';
import { Match, Switch } from 'solid-js';
import { useUser } from '../providers/user';
import { gql, mutationAction, useMutation } from '../graphql';
import type { ComponentProps } from 'solid-js';
import type { JSX } from 'solid-js/h/jsx-runtime';

type Props = ComponentProps<typeof Button> & {
	title?: JSX.Element;
};

export function SupportButton(props: Props) {
	const user = useUser();

	const requestSupport = useMutation(mutation);
	const submission = useSubmission(requestSupport);

	return (
		<DialogTrigger>
			<Button appearance="transparent" {...props}>
				{props.children ?? 'Contact support'}
			</Button>
			<Dialog header={props.title ?? props.children ?? 'Contact support'} headerLevel="h2" key="feedback">
				{(handleClose) => (
					<Switch>
						<Match when={submission.result?.data?.createSupportRequest.ok}>
							<div class="flex flex-col gap-4">
								<p>
									Thank you for reaching out! Your support request has been received, and our team will get back to you
									within one business day.
								</p>
								<DialogActions>
									<DialogAction
										onClick={() => {
											submission.clear();
											handleClose();
										}}
									>
										Close
									</DialogAction>
								</DialogActions>
							</div>
						</Match>
						<Match when>
							<Form action={requestSupport} class="flex flex-col gap-4 sm:min-w-96">
								<p>
									Need assistance? Contact our support team for help with your account or Troon Rewards. If you have an
									issue with an upcoming reservation, please call the course directly.
								</p>

								<TextField name="name" required>
									<Label>Name</Label>
									<Input readonly={!!user()} value={user() ? `${user()?.me.firstName} ${user()?.me.lastName}` : ''} />
								</TextField>

								<TextField name="email" required>
									<Label>Email</Label>
									<Input readonly={!!user()} inputMode="email" value={user()?.me.email} />
								</TextField>

								<TextField name="message" required>
									<Label>Message</Label>
									<Input multiline rows={4} />
								</TextField>

								<Errors />

								<DialogActions>
									<DialogAction type="submit">Submit</DialogAction>
								</DialogActions>
							</Form>
						</Match>
					</Switch>
				)}
			</Dialog>
		</DialogTrigger>
	);
}

const requestSupport = gql(`
mutation createSupportRequest($message: String!, $name: String, $email: String) {
	createSupportRequest(message: $message, name: $name, email: $email) {
		ok
	}
}
`);

const mutation = mutationAction(requestSupport, {
	track: {
		event: 'getSupport',
	},
});
