import { Match, Suspense, Switch, onMount, onCleanup, For, Show } from 'solid-js';
import { Avatar, Button, Dialog, DialogTrigger, Link, Logo, Menu, MenuItem, MenuTrigger } from '@troon/ui';
import {
	IconBellRing,
	IconCalendar,
	IconCaretDownMd,
	IconHamburgerMd,
	IconHouse01,
	IconMobileButton,
	IconStar,
	IconTag,
	IconUserCircle,
	IconUserSquare,
} from '@troon/icons';
import { twJoin } from '@troon/tailwind-preset/merge';
import { useUser } from '../providers/user';
import { AuthFlow } from '../partials/auth/auth';
import { createNumberFormatter } from '../modules/number-formatting';
import type { ComponentProps } from 'solid-js';

export const userNav: Array<ComponentProps<typeof MenuItem>> = [
	{ icon: IconUserSquare, href: '/account/', children: 'My account' },
	{ icon: IconCalendar, href: '/reservations/', children: 'Reservations' },
	{ icon: IconBellRing, href: '/alerts/', children: 'Tee time alerts' },
	{ icon: IconStar, href: '/courses/favorites/', children: 'Favorites' },
];

type Props = {
	hasHero?: boolean;
};

export function Nav(props: Props) {
	const user = useUser();

	let top: HTMLDivElement;
	let nav: HTMLDivElement;
	let observer: IntersectionObserver | undefined;

	const numberFormatter = createNumberFormatter();

	onMount(() => {
		if (typeof IntersectionObserver === 'undefined') {
			return;
		}

		observer = new IntersectionObserver((entries) => {
			if (!props.hasHero) {
				return;
			}
			if (entries.every((entry) => entry.isIntersecting)) {
				nav?.classList.add('with-hero');
			} else {
				nav?.classList.remove('with-hero');
			}
		});

		observer?.observe(top);
	});

	onCleanup(() => {
		observer?.unobserve(top);
		observer = undefined;
	});

	return (
		<>
			<div ref={top!} class="pointer-events-none absolute inset-x-0 top-0 h-12" />
			<div
				ref={nav!}
				// eslint-disable-next-line tailwindcss/no-custom-classname
				class={twJoin('group sticky inset-x-0 top-0 z-40', props.hasHero && 'with-hero')}
				/**
				 * Important! Use classList here because it's faster and more correct at applying the class names
				 * when navigating and avoiding major re-renders.
				 */
				classList={{ 'with-hero': props.hasHero }}
			>
				<nav
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class="h-16 border-b transition-colors duration-200 group-[.with-hero]:border-b-white/20 group-[:not(.with-hero)]:border-b-neutral-500 group-[.with-hero]:bg-transparent group-[:not(.with-hero)]:bg-white group-[.with-hero]:text-white group-[:not(.with-hero)]:text-brand"
				>
					<div class="relative flex h-full items-center justify-stretch gap-x-4 px-4 sm:px-6 lg:px-8">
						<Suspense>
							<Show when={user()}>
								<div class="shrink grow-0 lg:hidden">
									<MenuTrigger>
										<Button as={Link} href="/account" appearance="transparent-current" class="px-2 py-1">
											<IconHamburgerMd class="text-xl" />
											<span class="sr-only">Menu</span>
										</Button>
										<Menu placement="bottom-end">
											<MenuItem icon={IconHouse01} href="/">
												Home
											</MenuItem>

											<Show when={user()?.me.card?.id}>
												{(cardId) => (
													<MenuItem icon={IconTag} href={`/card/${cardId()}/`}>
														Troon Card booking
													</MenuItem>
												)}
											</Show>

											<MenuItem icon={IconCalendar} href="/reservations/">
												My reservations
											</MenuItem>
										</Menu>
									</MenuTrigger>
								</div>
							</Show>
						</Suspense>

						<div class={user() ? 'grow text-center lg:grow-0' : 'flex grow justify-items-start'}>
							<Button
								as={Link}
								appearance="transparent-current"
								href="/"
								// eslint-disable-next-line tailwindcss/no-arbitrary-value
								class="grow-0 px-2 py-1 group-[:not(.with-hero)]:text-neutral-900"
							>
								<Logo class="w-28" aria-label="Troon logo" />
								<span class="sr-only">Troon home</span>
							</Button>
						</div>

						<div class="hidden grow lg:block">
							<Suspense>
								<Show when={user()}>
									<ul
										// eslint-disable-next-line tailwindcss/no-arbitrary-value
										class="hidden flex-row justify-start gap-4 group-[.with-hero]:text-white group-[:not(.with-hero)]:text-neutral-900 lg:flex"
									>
										<li>
											<Button
												as={Link}
												class="px-2 py-1 font-medium normal-case"
												appearance="transparent-current"
												href="/"
											>
												Home
											</Button>
										</li>
										<Show when={user()?.me.card?.id}>
											{(cardId) => (
												<li>
													<Button
														as={Link}
														class="px-2 py-1 font-medium normal-case"
														appearance="transparent-current"
														href={`/card/${cardId()}/`}
													>
														Troon Card booking
													</Button>
												</li>
											)}
										</Show>
										<li>
											<Button
												as={Link}
												class="px-2 py-1 font-medium normal-case"
												appearance="transparent-current"
												href="/reservations/"
											>
												My reservations
											</Button>
										</li>
									</ul>
								</Show>
							</Suspense>
						</div>

						{/* Profile dropdown */}
						<div
							// eslint-disable-next-line tailwindcss/no-arbitrary-value
							class="flex shrink grow-0 flex-row items-center group-[.with-hero]:text-white group-[:not(.with-hero)]:text-current lg:ml-3"
						>
							<Suspense>
								<Switch>
									<Match when={user()}>
										<div class="me-2 hidden border-r border-current pe-2 lg:block">
											<Button
												as={Link}
												href="/app"
												target="_blank"
												class="px-2 py-1 normal-case"
												appearance="transparent-current"
											>
												<IconMobileButton /> Download app
											</Button>
										</div>
										<div>
											<MenuTrigger>
												<Button as={Link} href="/account" appearance="transparent-current" class="px-0 py-1 lg:px-2">
													<div
														// eslint-disable-next-line tailwindcss/no-arbitrary-value
														class="flex items-center gap-x-2"
													>
														<Avatar
															firstName={user()!.me.firstName}
															lastName={user()!.me.lastName}
															aria-label="Open user menu"
															class="size-8"
														/>{' '}
														<span class="hidden text-nowrap font-normal lg:block">
															{numberFormatter().format(user()!.userRewards?.availablePoints)}{' '}
															<abbr title="Points" class="no-underline">
																pts
															</abbr>
														</span>
														<IconCaretDownMd class="hidden lg:block" />
													</div>
												</Button>
												<Menu placement="bottom-end">
													<For each={userNav}>{(item) => <MenuItem {...item} />}</For>
													<MenuItem class="justify-center text-center font-semibold uppercase" href="/auth/logout">
														Log out
													</MenuItem>
												</Menu>
											</MenuTrigger>
										</div>
									</Match>

									<Match when={!user()}>
										<ul class="flex items-baseline gap-4 text-sm leading-3">
											<li class="block sm:hidden">
												<DialogTrigger>
													<Button appearance="transparent-current" as={Link} href="/auth" class="px-0 py-1">
														<span class="text-2xl leading-0">
															<IconUserCircle title="Sign in" />
														</span>
													</Button>
													<Dialog key="auth-nav-icon">{(handleClose) => <AuthFlow onComplete={handleClose} />}</Dialog>
												</DialogTrigger>
											</li>
											<li class="hidden border-r border-r-current pe-4 sm:block">
												<DialogTrigger>
													<Button appearance="transparent-current" as={Link} href="/auth">
														Join Troon Rewards
													</Button>
													<Dialog key="auth-nav-join">{(handleClose) => <AuthFlow onComplete={handleClose} />}</Dialog>
												</DialogTrigger>
											</li>
											<li class="hidden sm:block">
												<DialogTrigger>
													<Button appearance="transparent-current" as={Link} href="/auth">
														Sign in{' '}
														<span class="-my-2 inline-block text-xl leading-0">
															<IconUserCircle />
														</span>
													</Button>
													<Dialog key="auth-nav-login">{(handleClose) => <AuthFlow onComplete={handleClose} />}</Dialog>
												</DialogTrigger>
											</li>
										</ul>
									</Match>
								</Switch>
							</Suspense>
						</div>
					</div>
				</nav>
			</div>
		</>
	);
}
