import { Button, Errors, Form, Input, Label, TextField } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import debounce from 'debounce';
import { Switch, Match, createSignal, For } from 'solid-js';
import { Navigate } from '@solidjs/router';
import { unwrap } from 'solid-js/store';
import { useAuthStore } from '../flow';
import { SharedPasswordEntry } from './shared-password-entry';
import { register, signupQuery } from './03-postalcode';

function createOnce(fn: () => void): () => void {
	const [called, setCalled] = createSignal(false);
	return () => {
		if (!called()) {
			fn();
			setCalled(true);
		}
	};
}

export function Join() {
	const [store, setStore] = useAuthStore();
	const trackEvent = useTrackEvent();
	const action = register(trackEvent, () => {});

	const handleSetFirstName = debounce((value: string) => {
		setStore('data', 'firstName', value);
	});
	const handleSetLastName = debounce((value: string) => {
		setStore('data', 'lastName', value);
	});

	const [valid, setValid] = createSignal(false);
	const trackName = createOnce(() => {
		trackEvent('signupEnteredName', { email: store.data.email });
	});
	const trackPostalCode = createOnce(() => {
		trackEvent('signupEnteredZipcode', { email: store.data.email });
	});
	const trackPassword = createOnce(() => {
		trackEvent('signupEnteredPassword', { email: store.data.email });
	});

	return (
		<Switch>
			<Match when={!store.data.email}>
				<Navigate href="/auth" />
			</Match>
			<Match when={true}>
				<div class="@container">
					<Form action={action} document={signupQuery} class="grid gap-4 @xl:grid-cols-2">
						<For each={Object.entries(unwrap(store.data))}>
							{([key, value]) => <input type="hidden" name={key} value={value} />}
						</For>

						<TextField name="firstName">
							<Label suppressRequired>First name</Label>
							<Input
								onInput={(e) => {
									handleSetFirstName(e.currentTarget.value);
									if (store.data.lastName && e.currentTarget.value) {
										trackName();
									}
								}}
							/>
						</TextField>

						<TextField name="lastName">
							<Label suppressRequired>Last name</Label>
							<Input
								onInput={(e) => {
									handleSetLastName(e.currentTarget.value);
									if (store.data.firstName && e.currentTarget.value) {
										trackName();
									}
								}}
							/>
						</TextField>

						<TextField name="zipcode" class="@xl:col-span-2">
							<Label suppressRequired>Postal code</Label>
							<Input inputMode="numeric" onInput={trackPostalCode} />
						</TextField>

						<div class="flex flex-col gap-4 @xl:col-span-2">
							<SharedPasswordEntry
								onUpdate={(valid) => {
									setValid(valid);
									trackPassword();
								}}
							/>
						</div>

						<Errors />

						<div class="flex flex-col gap-4 @xl:col-span-2">
							<div>
								<Button type="submit" disabled={!valid()} class="w-auto">
									Continue
								</Button>
							</div>
						</div>
					</Form>
				</div>
			</Match>
		</Switch>
	);
}
