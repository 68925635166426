import { IconCheck, IconCloseMd } from '@troon/icons';
import { Input, TextField, Label } from '@troon/ui';
import { For, Match, Switch, createEffect, createSignal, createUniqueId } from 'solid-js';
import { createStore } from 'solid-js/store';
import debounce from 'debounce';
import { useAuthStore } from '../flow';

type Props = {
	onUpdate: (isValid: boolean) => void;
};

export function SharedPasswordEntry(props: Props) {
	const [store, setStore] = useAuthStore();
	const [validations, setValidations] = createStore<Record<keyof typeof requirements, boolean>>({});
	const requirementsId = createUniqueId();
	const [confirmation, setConfirmation] = createSignal<string>('');

	const handleChange = debounce((name: string, value: string) => {
		setStore('data', name, value);

		for (const [key, fn] of Object.entries(requirements)) {
			setValidations(key, fn(value));
		}
	}, 100);

	createEffect(() => {
		props.onUpdate(confirmation() === store.data.password && Object.values(validations).every((v) => !!v));
	});

	return (
		<>
			<TextField name="password" aria-describedby={requirementsId}>
				<Label suppressRequired>Password</Label>
				<Input
					minLength={8}
					type="password"
					onInput={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
				/>
			</TextField>

			<TextField name="confirm-password">
				<Label suppressRequired>Confirm password</Label>
				<Input type="password" onInput={(e) => setConfirmation(e.currentTarget.value)} />
			</TextField>

			<div id={requirementsId} class="@container">
				<h3 class="mb-2 font-semibold">Requirements</h3>
				<ul class="grid gap-1 ps-2 @xl:grid-cols-2">
					<For each={Object.keys(requirements)}>
						{(label) => (
							<li class="flex items-center gap-1">
								<Switch>
									<Match when={validations[label]}>
										<IconCheck title="Valid" class="text-green-600" />
									</Match>
									<Match when>
										<IconCloseMd title="Invalid" class="text-red-500" />
									</Match>
								</Switch>
								{label}
							</li>
						)}
					</For>
				</ul>
			</div>
		</>
	);
}

const requirements: Record<string, (v: string) => boolean> = {
	'At least eight characters': (value) => value.length >= 8,
	'At least one special symbol': (value) => new RegExp('[!@#$%^&*()]').test(value),
	'At least one uppercase letter': (value) => /[A-Z]+/.test(value),
	'At least one lowercase letter': (value) => /[a-z]+/.test(value),
	'At least one number': (value) => /[0-9]+/.test(value),
};
