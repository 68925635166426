import { Suspense, Show, Switch, Match } from 'solid-js';
import { Link, Logo, Button } from '@troon/ui';
import { useLocation } from '@solidjs/router';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Nav } from '../components/nav';
import { Hero } from '../components/hero';
import { Footer } from '../components/footer';
import { useUser } from '../providers/user';
import type { ParentProps, JSX } from 'solid-js';

type Props = ParentProps<{
	banner?: () => JSX.Element;
	hero?: string;
	heroElement?: () => JSX.Element;
	heroContent?: () => JSX.Element;
	hideNav?: boolean;
	minimalNav?: boolean;
}>;

export function BaseLayout(props: Props) {
	const location = useLocation();
	const user = useUser();

	return (
		<>
			<Show when={!location.pathname.endsWith('/')}>
				<Link rel="canonical" href={`${location.pathname}/`} />
			</Show>
			<div class="flex min-h-screen w-screen flex-col justify-stretch">
				<Switch>
					<Match when={props.minimalNav}>
						<nav class="absolute inset-x-0 top-0 z-40 flex h-16 flex-row items-center justify-center border-b border-b-white/20 bg-transparent text-white">
							<Button
								as={Link}
								appearance="transparent-current"
								href="/"
								// eslint-disable-next-line tailwindcss/no-arbitrary-value
								class="grow-0 px-2 py-1 "
							>
								<Logo class="w-28" aria-label="Troon logo" />
								<span class="sr-only">Troon home</span>
							</Button>
						</nav>
					</Match>
					<Match when={!props.hideNav && !props.minimalNav}>
						<Suspense>
							<Nav hasHero={!!props.heroElement || Boolean(props.hero)} />
							<Switch>
								<Match when={props.hero}>
									<Hero src={props.hero}>{props.heroContent ? props.heroContent() : null}</Hero>
								</Match>
								<Match when={props.heroElement}>
									{(element) => <div class="relative -top-16 -mb-24">{element()()}</div>}
								</Match>
							</Switch>
						</Suspense>
					</Match>
				</Switch>
				<main
					id="content"
					class={twJoin(
						'flex shrink-0 grow flex-col',
						!props.hideNav && !props.minimalNav && 'pb-6 pt-8 md:pb-12 md:pt-16',
					)}
				>
					{props.children}
				</main>
				<footer class={twJoin('bg-white', user() ? 'shrink-0 pb-24 md:pb-4' : 'shrink-0 pb-4')}>
					<Footer />
				</footer>
			</div>
		</>
	);
}
